// improve later
import Vue from 'vue'
import router from '@/router'
import { EPMC_API_FT_REPO_ROOT, EPMC_ROOT, PREPRINTS } from '@/config'
import { fetchNbkID } from '@/api'
import { scrollTo } from 'epmc-patterns/helpers'
import { addLink, addScript } from '@/helpers/load-script-and-style'
import { registerMatomoEvent } from '@/helpers/matomo'
import { Loading } from 'epmc-patterns/components/v2'
import CommentsAndCorrections from '@/templates/article/CommentsAndCorrections'
import { formatDoi } from '@/helpers/doi'

function replaceText(text) {
  let newText = text

  // newText = newText.replace(/<(\w+)(.+?)\/>/g, '<$1$2></$1>')
  newText = newText.replace(/<!\[CDATA\[/g, '')
  newText = newText.replace(/]]>/g, '')
  newText = newText.replace('.pmc-wm', '.epmc-elements .pmc-wm')
  newText = newText.replace(/(">)PubMed(<\/a>])/g, '$1Abstract$2')
  newText = newText.replace(
    /(">)(PMC free article<\/a>]<\/span>)/g,
    '$1Europe $2'
  )
  newText = newText.replace(
    /<div><h2>(Contents<\/h2>)/g,
    '<div id="toc_id"><h2 id="toc_idtitle">$1'
  )
  newText = newText.replace(
    /(targettype=pubmed">)PubMed(<span class="bk_prnt">)/g,
    '$1Abstract$2'
  )
  newText = newText.replace(/(wm-retraction)(.png)/g, '$-pink$2')

  newText = newText.replace(/https:\/\/europepmc.org\//g, EPMC_ROOT)
  newText = newText.replace(
    new RegExp(EPMC_ROOT + 'abstract', 'g'),
    EPMC_ROOT + 'article'
  )
  newText = newText.replace(
    /"https:\/\/europepmc.org\/articles\/([^/]+)\/"/g,
    '"' + EPMC_ROOT + 'search?query=$1"'
  )

  newText = newText.replace(/(href=")[^//]\//g, '$1' + EPMC_ROOT)
  newText = newText.replace(/(src=")\//g, '$1' + EPMC_ROOT)
  newText = newText.replace(/(src=")(corehtml)/g, '$1' + EPMC_ROOT + '$2')
  newText = newText.replace(/(src=")\/(corehtml)/g, '$1' + EPMC_ROOT + '$2')
  newText = newText.replace(/(src=").\/(corehtml)/g, '$1' + EPMC_ROOT + '$2')
  newText = newText.replace(/(url\()(corehtml)/g, '$1' + EPMC_ROOT + '$2')
  newText = newText.replace(/(url\()\/(corehtml)/g, '$1' + EPMC_ROOT + '$2')
  newText = newText.replace(/(url\().\/(corehtml)/g, '$1' + EPMC_ROOT + '$2')
  newText = newText.replace(
    /(<a href=[^>]+)target="pmc_ext"([^>]+>Abstract<\/a>)/g,
    '$1$2'
  )
  return newText
}

/* for books */
function controlContentLinkOfTOC(byDefault) {
  // Hide the second 'Contents' link at the bottom
  const links = document.querySelectorAll('div.bookContentPage a.bk_cntns')
  if (links && links.length === 2) {
    links[1].parentNode.removeChild(links[1])
  }

  let expanded = false
  const linkContainer = document.querySelector(
    'div.bookContentPage div.pre-content'
  )
  if (linkContainer) {
    if (byDefault) {
      linkContainer.classList.remove('expanded')
    } else {
      if (linkContainer.classList.contains('expanded')) {
        linkContainer.classList.remove('expanded')
      } else {
        linkContainer.classList.add('expanded')
        expanded = true
      }
    }
  }
  return expanded
}

function hideSomeContentOfTOC() {
  document.querySelector('label.source-contents-label').style.display = 'none'
}

function toggleCopyright() {
  const copyrightLink = document.querySelector('a.jig-ncbidialog')
  if (copyrightLink) {
    let icon = document.querySelector('a.jig-ncbidialog > i')
    if (!icon) {
      icon = document.createElement('i')
      icon.className = 'fas fa-caret-right'
      copyrightLink.appendChild(icon)
    }

    copyrightLink.href = 'javascript:void(0)'
    copyrightLink.onclick = () => {
      const copyright = document.querySelector('div.jig-ncbidialog-box')
      copyright.style.display = copyright.style.display === 'none' ? '' : 'none'
      if (copyright.style.display === 'none') {
        icon.setAttribute('class', 'fas fa-caret-right')
      } else {
        icon.setAttribute('class', 'fas fa-caret-down')
      }
    }
  }
}

function toggleAuthorInfo() {
  const authorLink = document.querySelector('a.jig-ncbitoggler')
  if (authorLink) {
    const authorLinkCloned = authorLink.cloneNode(true)
    authorLinkCloned.classList.remove('openNext')
    authorLink.parentNode.replaceChild(authorLinkCloned, authorLink)

    authorLinkCloned.href = 'javascript:void(0)'
    authorLinkCloned.onclick = () => {
      const authors = document.querySelector('a.jig-ncbitoggler + div')
      authors.style.display = authors.style.display === 'none' ? '' : 'none'

      const icon = document.querySelector('a.jig-ncbitoggler > i')
      if (icon) {
        if (authors.style.display === 'none') {
          icon.setAttribute('class', 'fas fa-caret-right')
        } else {
          icon.setAttribute('class', 'fas fa-caret-down')
        }
      }
    }
  }
}

function handleExpandCollapseTOC() {
  const boxes = document.querySelectorAll('div.bktoc_all_cntnr')
  if (boxes) {
    boxes.forEach((box) => {
      box.style.display = ''
    })
  }

  const expandLinks = document.querySelectorAll('a.bktoc_all_exp')
  if (expandLinks) {
    expandLinks.forEach((e) => {
      e.href = 'javascript:void(0)'
      e.onclick = () => {
        const exps = document.querySelectorAll('ul.bktoc_lst_exp')
        if (exps) {
          exps.forEach((exp) => {
            exp.style.display = ''
          })
        }
      }
    })
  }

  const collapseLinks = document.querySelectorAll('a.bktoc_all_clps')
  if (collapseLinks) {
    collapseLinks.forEach((e) => {
      e.href = 'javascript:void(0)'
      e.onclick = () => {
        const exps = document.querySelectorAll('ul.bktoc_lst_exp')
        if (exps) {
          exps.forEach((exp) => {
            exp.style.display = 'none'
          })
        }
      }
    })
  }
}

function openFigTableInNewWindow() {
  const links = document.querySelectorAll('a[href*="report=objectonly"]')
  if (links) {
    links.forEach((link) => {
      const href = link.href
      link.onclick = (e) => {
        e.preventDefault()
        window.open(href, 'Test', 'height=800,width=800')
      }
    })
  }
}

function invalidPMID() {
  const pmidLink = document.querySelector('div.post-content div.small a')
  if (pmidLink) {
    const pmidElement = document.createElement('span')
    pmidElement.textContent = pmidLink.textContent
    pmidLink.parentNode.replaceChild(pmidElement, pmidLink)
  }
}

function bindBookLinks(regx, src, extID) {
  const links = [...document.querySelectorAll(regx)]
  links.map((e, i) => {
    e.setAttribute('id', `book-link-${i}`)
    let attrStr = Array.from(e.attributes).reduce((attrs, attr) => {
      if (attr.nodeName === 'id' || attr.nodeName === 'href') {
        return attrs
      }
      attrs !== '' && (attrs += ' ')
      attrs += `${attr.nodeName}="${attr.nodeValue}"`
      return attrs
    }, '')
    const href = e.getAttribute('href')

    // Update href for right click (CIT-5703)
    if (href) {
      const regx = new RegExp('(.+)#(.+)')
      const matchedArray = regx.exec(href)
      if (matchedArray && matchedArray.length === 3) {
        attrStr =
          attrStr +
          'href="' +
          matchedArray[1] +
          '?extID=' +
          extID +
          '&src=' +
          src +
          '&fid=' +
          matchedArray[2] +
          '"'
      } else {
        attrStr = `${attrStr} href="${href}?extID=${extID}&src=${src}"`
      }
    }

    const className = e.getAttribute('class')

    new Vue({
      el: `#book-link-${i}`,
      router,
      components: { Loading },
      data() {
        return {
          displayToc: false,
          toc: '',
          tocLoaded: false,
          tocLoading: false,
          expanded: false,
          innerHTML: e.innerHTML,
          linkDisplay: 'inline', // className === 'bk_pop' ? 'inline' : 'inline'
        }
      },
      mounted() {
        if (className === 'bk_cntns') {
          this.displayToc = true
        }
      },
      methods: {
        renderBook(url) {
          let fragmentID = '#free-full-text'
          const regx = new RegExp('^/books([^#]+)(#?.*)$')
          const matchedArray = regx.exec(url)
          if (matchedArray && matchedArray.length === 3) {
            const bookPath = matchedArray[1]
            if (matchedArray[2]) {
              fragmentID = matchedArray[2]
              scrollTo(matchedArray[2], matchedArray[2])
            } else {
              const fulltextSelector = '#free-full-text'
              const fulltextSection = document.querySelector(fulltextSelector)
              if (fulltextSection) {
                scrollTo(fulltextSelector, fulltextSelector)
              }
            }

            if (className === 'bk_cntns') {
              this.expanded = controlContentLinkOfTOC()
              if (this.tocLoaded) {
                return
              }
              this.tocLoading = true
              fetchNbkID(bookPath, true).then((toc) => {
                this.toc = toc
                this.tocLoaded = true
                this.tocLoading = false
                this.$nextTick(() => {
                  hideSomeContentOfTOC()
                  bindBookLinks(
                    'div#source-navigation a[href^="/books/n"]',
                    src,
                    extID
                  )
                })
              })
            } else {
              fetchNbkID(bookPath).then((response) => {
                this.$router.push(
                  `/article/${src}/${extID}/${response.nbkID}${fragmentID}`
                )
              })
            }
          }
        },
      },
      template: `
        <div :style="{ display: linkDisplay }">
          <div v-if="displayToc" class="book-tooltip">
            <a ${attrStr} @click.prevent='renderBook("${href}")' v-html='innerHTML'></a>
            <div v-show="toc && expanded" v-html="toc" class="book-tooltip-text"></div>
            <Loading v-if="tocLoading" />
          </div>
          <a v-else ${attrStr} @click.prevent='renderBook("${href}")' v-html='innerHTML'></a>
        </div>
      `,
    })
  })
}
/* for books */

export function processFulltextDoc(fulltextId, response) {
  const doc = response.preprintDocument || response.pmcDocument || response
  let fulltext = ''
  if (response.preprintDocument || response.pmcDocument) {
    const tempDiv = document.createElement('div')
    tempDiv.appendChild(doc)

    //change url of doi to must have https://doi.org prepended if not and also replace if dx is found in the domain name
    processDoi(tempDiv)
    fulltext = tempDiv.innerHTML
    fulltext = fulltext.replace(/doi:&nbsp;/g, '')
    fulltext = fulltext.replace(/doi:/g, '')
    // handle math equations on firefox
    fulltext = fulltext
      .replace(/&lt;math/g, '<math')
      .replace(/MathML"&gt;/g, 'MathML">')
      .replace(/&lt;\/math&gt;/g, '</math>')
    response.files.forEach((file) => {
      const { filename, type, mimeType } = file

      const linkFileNames = [filename]
      // whether needing the range of the id***
      if (
        fulltextId.startsWith('PPR') &&
        fulltextId >= 'PPR7001' &&
        fulltextId <= 'PPR7035' &&
        type === 'supplement'
      ) {
        const parts = filename.split('.')
        const fileExt = parts.pop()
        let newFileName = parts.join('.')
        const fid = newFileName.split('-').pop()
        newFileName = fulltextId + '-supplement-s' + ('00' + fid).slice(-3)

        linkFileNames[0] = newFileName + '.' + fileExt
        if (fileExt === 'xls') {
          linkFileNames[1] = newFileName + '.xlsx'
        }
      }

      linkFileNames.forEach((linkFileName) => {
        fulltext = fulltext.replace(
          new RegExp('"([^"]+)' + linkFileName + '"', 'g'),
          EPMC_API_FT_REPO_ROOT +
            (fulltextId.startsWith('PPR') ? 'pprId' : 'pmcId') +
            '=' +
            fulltextId +
            '&type=FILE&fileName=' +
            filename +
            '&mimeType=' +
            mimeType
        )
      })
    })
  } else if (['PMC', 'NBK', 'mid'].includes(fulltextId.substring(0, 3))) {
    if (['PMC', 'mid'].includes(fulltextId.substring(0, 3))) {
      // tempDiv used to update document head and fulltext; tempBody used for query
      const tempDiv = document.createElement('div')

      let tempBody = ''
      if (typeof doc === 'string') {
        tempBody = document.createElement('div')
        tempBody.innerHTML = replaceText(doc)
      } else if (typeof doc === 'object') {
        tempBody = doc
      }

      //Get blue sidebar
      const headStyle = tempBody.querySelector(
        'content-area[name="html-head-supplement"] > content'
      ).innerHTML
      tempDiv.innerHTML = replaceText(
        headStyle.substring(
          headStyle.indexOf('<style '),
          headStyle.indexOf('</style>')
        ) + '</style>'
      )
      document.head.appendChild(tempDiv.firstChild)

      tempDiv.innerHTML = replaceText(
        tempBody.querySelector('content-area[name="page-banner"] > content')
          .innerHTML
      )
      fulltext += tempDiv.innerHTML

      tempDiv.innerHTML = replaceText(
        tempBody.querySelector('content-area[name="page-body"] > content')
          .innerHTML
      )
      tempDiv.firstChild.className = ''
      fulltext += tempDiv.innerHTML
    } else if (fulltextId.substring(0, 3) === 'NBK') {
      if (doc.querySelector('div.pre-content')) {
        fulltext += doc.querySelector('div.pre-content').outerHTML
      }
      if (doc.querySelector('div.source-links')) {
        fulltext += doc.querySelector('div.source-links').outerHTML
      }
      if (doc.querySelector('div.main-content')) {
        fulltext += doc.querySelector('div.main-content').outerHTML
      }
      if (doc.querySelector('div.post-content')) {
        fulltext += doc.querySelector('div.post-content').outerHTML
      }
      fulltext = replaceText(fulltext)
    }

    // wrapper used to query and update fulltext
    const wrapper = document.createElement('div')
    wrapper.innerHTML = fulltext

    const citeLinks = wrapper.querySelectorAll('a[href$="citedby/"]')
    for (const link of citeLinks) {
      link.href = '#impact'
      link.innerHTML += '<i class="far fa-arrow-alt-circle-down"></i>'
      link.classList.add('openCitations')
    }

    const versionLinks = wrapper.querySelectorAll(
      '.fm-citation-ids a[class="int-reflink"]'
    )
    for (const link of versionLinks) {
      link.dataset.id = link.innerHTML
      link.dataset.source = link.href.indexOf('/mid/') >= 0 ? 'mid/' : ''
      link.classList.add('reloadFulltext')
    }

    const nextToggles = wrapper.querySelectorAll(
      '.meta-content .jig-ncbitoggler, .icnblk_cntnt .jig-ncbitoggler, a[href="#other_ver_id"]'
    )
    for (const toggle of nextToggles) {
      toggle.href = '#'
      toggle.classList.add('openNext')
      const i = document.createElement('i')
      i.setAttribute('class', 'fas fa-caret-right')
      toggle.appendChild(i)
    }

    //change url of doi to must have https://doi.org prepended if not and also replace if dx is found in the domain name
    processDoi(wrapper)
    fulltext = wrapper.innerHTML
    fulltext = fulltext.replace(/doi:&nbsp;/g, '')
    fulltext = fulltext.replace(/doi:/g, '')
  }

  return fulltext
}

function processDoi(wrapper) {
  const doi = wrapper.querySelector('.doi')
  if (doi) {
    const a = doi.querySelector('a')
    if (a) {
      a.textContent = formatDoi(a.textContent)
      a.href = a.href.replace(/dx\.doi\.org/g, 'doi.org/')
    }
  }
}

export function addStylesAndScripts(fulltextId, response) {
  // add style if not adding it yet
  const styles = []
  if (fulltextId.startsWith('PMC')) {
    styles.push(
      EPMC_ROOT + 'corehtml/pmc/css/3.3/ncbi.min.css',
      EPMC_ROOT + 'corehtml/pmc/css/3.3/pmc.min.css',
      // EPMC_ROOT + 'corehtml/pmc/css/3.3/pmc_extras_prnt.min.css',
      EPMC_ROOT + 'corehtml/pmc/jig/1.11/css/jig.core.theme.min.css',
      EPMC_ROOT + 'corehtml/pmc/jig/1.11/css/jquery.ui.widgets.min.css'
    )
  } else if (fulltextId.startsWith('NBK')) {
    styles.push(
      EPMC_ROOT + 'corehtml/pmc/jig/1.14.2/css/jig.min.css',
      EPMC_ROOT + 'corehtml/pmc/css/bookshelf/2.10/ncbi.css',
      EPMC_ROOT + 'corehtml/pmc/css/figpopup.css',
      EPMC_ROOT + 'corehtml/pmc/css/bookshelf/2.10/books.css'
      // EPMC_ROOT + 'corehtml/pmc/css/bookshelf/2.10/books_print.css'
    )
  }
  for (const style of styles) {
    addLink({
      href: style,
      insertBefore: document.head.querySelector('link[href*="static/css/app"]'),
    })
  }

  // add scripts if not adding them yet
  const scripts = []
  if (response.preprintDocument) {
    scripts.push(
      'https://cdnjs.cloudflare.com/ajax/libs/mathjax/2.7.5/MathJax.js?config=TeX-MML-AM_CHTML'
    )
  } else if (fulltextId.startsWith('PMC') || fulltextId.startsWith('mid')) {
    scripts.push(
      EPMC_ROOT + 'corehtml/pmc/jig/1.11/js/jig.min.js',
      EPMC_ROOT + 'corehtml/pmc/jig/1.11/js/jquery.ui.widgets.min.js',
      EPMC_ROOT + 'corehtml/pmc/js/common.min.js',
      EPMC_ROOT + 'corehtml/pmc/js/NcbiTagServer.min.js',
      EPMC_ROOT + 'corehtml/pmc/js/large-obj-scrollbars.min.js',
      'https://cdn.jsdelivr.net/npm/mathjax@3.0.1/es5/tex-mml-chtml.js'
    )
  } else if (fulltextId.startsWith('NBK')) {
    // scripts.push(
    //   EPMC_ROOT + 'corehtml/pmc/jig/1.14.2/js/jig.min.js',
    //   EPMC_ROOT + 'corehtml/pmc/js/jquery.hoverIntent.min.js',
    //   EPMC_ROOT + 'corehtml/pmc/js/common.min.js',
    //   EPMC_ROOT + 'corehtml/pmc/js/large-obj-scrollbars.min.js',
    //   EPMC_ROOT + 'corehtml/pmc/js/bookshelf/2.10/book-toc.js',
    //   EPMC_ROOT + 'corehtml/pmc/js/bookshelf/2.10/books.js'
    // )
  }
  for (const script of scripts) {
    addScript({ src: script })
  }
}

export function updateFulltextDom() {
  const { abstract } = this
  const { source, id, bookid } = abstract
  const citesLinks = document.querySelectorAll('a.openCitations')
  for (const link of citesLinks) {
    link.addEventListener('click', () =>
      this.changeSectionVariable({ id: 'impact', visible: true })
    )
  }

  const versionLinks = document.querySelectorAll('a.reloadFulltext')
  for (const link of versionLinks) {
    link.href = '/article/' + source + '/' + id
    link.addEventListener('click', (e) => {
      e.preventDefault()
      this.loadFulltextHtml(link.dataset.source + link.dataset.id)
    })
  }

  const nextToggles = document.querySelectorAll('a.openNext')
  for (const link of nextToggles) {
    link.addEventListener('click', (e) => {
      e.preventDefault()
      e.target.firstElementChild.classList.toggle('fa-caret-right')
      e.target.firstElementChild.classList.toggle('fa-caret-down')
      if (e.target.nextElementSibling.style.display === 'none') {
        e.target.nextElementSibling.style.display = 'block'
      } else {
        e.target.nextElementSibling.style.display = 'none'
      }
    })
  }

  const emails = document.querySelectorAll('a.oemail')
  for (const email of emails) {
    email.addEventListener(
      'click',
      (e) => (e.currentTarget.href = e.currentTarget.getAttribute('data-email'))
    )
  }

  const figs = document.querySelectorAll('.fig-expansion img')
  for (const fig of figs) {
    const f = window.getComputedStyle(fig)
    if (f.width > f.height) {
      fig.classList.add('center')
    }
  }

  const registerMatomoEventForFulltext = (eventName) => {
    if (eventName.includes('Author information')) {
      eventName = 'Author information'
    } else if (eventName.includes('Article notes')) {
      eventName = 'Article notes'
    } else if (eventName.includes('Copyright and License information')) {
      eventName = 'Copyright and License info'
    }
    registerMatomoEvent('Article', 'Full text', eventName)
  }

  const bannerIdentifier = document.querySelector('map#logo-imagemap > area')
  if (bannerIdentifier != null) {
    bannerIdentifier.addEventListener('click', () => {
      registerMatomoEventForFulltext('Journal banner image')
    })
  }

  const togglers = document.querySelectorAll('div.togglers a.pmctoggle')
  for (const toggler of togglers) {
    toggler.addEventListener('click', (e) => {
      registerMatomoEventForFulltext(e.target.textContent)
    })
  }

  const pubMedReferences = document.querySelectorAll('span.ref.pubmed a')
  for (const pubMedReference of pubMedReferences) {
    pubMedReference.addEventListener('click', () => {
      registerMatomoEventForFulltext('References - Pubmed')
    })
  }

  const pmcReferences = document.querySelectorAll('span.ref.pmc a')
  for (const pmcReference of pmcReferences) {
    pmcReference.addEventListener('click', () => {
      registerMatomoEventForFulltext('References - PMC free article')
    })
  }

  const figureImages = document.querySelectorAll('div.figure')
  for (const figureImage of figureImages) {
    figureImage.addEventListener('click', () => {
      registerMatomoEventForFulltext('Figure popup')
    })
  }

  const figureLinks = document.querySelectorAll('a.figpopup')
  for (const figureLink of figureLinks) {
    figureLink.addEventListener('click', () => {
      registerMatomoEventForFulltext('Figure popup')
    })
  }

  const figureOpenLinks = document.querySelectorAll(
    'div.fig div.largeobj-link a'
  )
  for (const figureOpenLink of figureOpenLinks) {
    figureOpenLink.addEventListener('click', () => {
      registerMatomoEventForFulltext('Figure popup')
    })
  }

  const tableLinks = document.querySelectorAll(
    'div.table-wrap div.largeobj-link a'
  )
  for (const tableLink of tableLinks) {
    tableLink.addEventListener('click', () => {
      registerMatomoEventForFulltext('Table popup')
    })
  }

  if (bookid) {
    bindBookLinks('a[href^="/books/n"]', source, id)
    toggleCopyright()
    toggleAuthorInfo()
    handleExpandCollapseTOC()
    controlContentLinkOfTOC(true)
    openFigTableInNewWindow()
    invalidPMID()
  } else if (source === 'PPR') {
    const publisher = abstract.bookOrReportDetails.publisher
    const preprint = PREPRINTS.filter((p) => p.fulltextIndexed).find(
      (ppr) => ppr.name === publisher
    )
    const ftContainerElm = document.querySelector('#fulltextcontent')

    // add the logo
    const imgElm =
      '<a href="' +
      preprint.url +
      '"><img src=' +
      require('@/assets/preprint_logos/' + preprint.imageUrl) +
      ' style="width:' +
      preprint.imageWidth +
      'px" alt="' +
      preprint.name +
      '"></img></a>'
    ftContainerElm.innerHTML = imgElm + ftContainerElm.innerHTML

    // add the notification
    const frontMatterElm = document.querySelector('.front-matter')
    frontMatterElm.innerHTML =
      frontMatterElm.innerHTML + '<div id="preprint-notification"></div>'
    new Vue({
      el: '#preprint-notification',
      router,
      components: { CommentsAndCorrections },
      data() {
        return {
          notificationList: abstract.commentCorrectionList.commentCorrection,
        }
      },
      template: `
        <comments-and-corrections
          :comment-correction-list="notificationList"
          :show="1"
          :allowed-nofitication-styles="['warning', 'error']"
        />
      `,
    })
    // correct email address
    const emailElms = document.querySelectorAll('a[href="mailto:dev@null"]')
    for (let i = 0; i < emailElms.length; i++) {
      const addr = emailElms[i]
        .getAttribute('data-email')
        .split('')
        .reverse()
        .join('')
      emailElms[i].onclick = (e) => {
        e.preventDefault()
        location.href = 'mailto:' + addr
      }
    }
  }
}
